import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/main.css';
import store from './store';
import Root from './containers/Root';

const appContainerElement = document.getElementById('app');

const mountDevRoot = () => {
  const AppContainer = require('react-hot-loader').AppContainer;
  if (appContainerElement != null) {
    ReactDOM.render(
      <AppContainer>
        <Root store={store} />
      </AppContainer>,
      appContainerElement
    );
  }
};

if (process.env.NODE_ENV === 'production') {
  if (appContainerElement != null) {
    ReactDOM.render(
      <Root store={store} />,
      appContainerElement
    );
  }
} else {
  mountDevRoot();
}

if ((module as any).hot) {
  (module as any).hot.accept('./containers/Root', mountDevRoot);
}
