import {connect} from 'react-redux';
import UserForm from './';
import * as actions from 'internal-tools/actions/accounts';
import {BLANK_USER} from 'internal-tools/constants/user';


const mapStateToProps = () => ({
  user: BLANK_USER,
  passwordOptional: false,
  isUpdating: false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submit: (user, ticket) => dispatch(actions.createUser(ownProps.maid, user, ticket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
