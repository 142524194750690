import React from 'react';

import SubmitButton from 'internal-tools/components/SubmitButton';
import accountStyles from 'internal-tools/styles/accounts.css';
import sharedStyles from 'internal-tools/styles/shared.css';


export default class PdfRegenerationForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      submissionId: '',
      ticket: '',
    };
  }

  handleSubmissionIdChange = event => {
    this.setState({ submissionId: event.target.value });
  };

  handleTicketChange = event => {
    this.setState({ ticket: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { submissionId, ticket } = this.state;
    this.setState({
      submissionId: '',
      ticket: '',
    }, () => this.props.regeneratePdf(submissionId, ticket));
  };

  render() {
    const {
      ticketInput,
      pdfRegenerationContainer,
      pdfRegenerationInputLabel,
      pdfRegenerationInputContainer,
      pdfRegenerationForm,
    } = accountStyles;

    return (
      <div className={pdfRegenerationContainer}>
        <p>Pdf Regeneration</p>
        <div>
          <form className={pdfRegenerationForm} onClick={e => e.stopPropagation()} onSubmit={this.handleSubmit}>
            <div className={pdfRegenerationInputContainer}>
              <label htmlFor='regeneration-submission-id' className={pdfRegenerationInputLabel}>
                Regeneration Submission ID
              </label>
              <input
                id='regeneration-submission-id'
                className={ticketInput}
                onChange={this.handleSubmissionIdChange}
                value={this.state.submissionId}
                placeholder={'AP#################'}
                type='string'
              />
            </div>
            <div className={pdfRegenerationInputContainer}>
              <label htmlFor='credits-jira-ticket' className={pdfRegenerationInputLabel}>
                JUSTIFICATION
              </label>
              <input
                id={'credits-jira-ticket'}
                className={ticketInput}
                value={this.state.ticket}
                onChange={this.handleTicketChange}
                placeholder='Jira Ticket #'
              />
            </div>

            <SubmitButton
              classes={{ root: sharedStyles.subdomainSubmit }}
              disabled={!this.state.submissionId || !this.state.ticket}
              isFetching={false}
              status={'pending'}
            />
          </form>
        </div>
      </div>
    );
  }
}
