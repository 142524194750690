export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_BY_PAGINATION = 'GET_ACCOUNTS_BY_PAGINATION';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const UPDATE_SUBDOMAIN = 'UPDATE_SUBDOMAIN';
export const GET_FEATURES = 'GET_FEATURES';
export const TOGGLE_FEATURE = 'TOGGLE_FEATURE';
export const SAVE_FEATURE_CHANGES = 'SAVE_FEATURE_CHANGES';
export const GET_USERS = 'GET_USERS';
export const GET_CREDITS = 'GET_CREDITS';
export const UPDATE_CREDITS = 'UPDATE_CREDITS';
export const REGENERATE_PDF = 'REGENERATE_PDF';
export const SELECT_USER = 'SELECT_USER';
export const SET_USER_MODAL = 'SET_USER_MODAL';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const TRANSFER_USER_DATA = 'TRANSFER_USER_DATA';
export const RESET_FETCHING = 'RESET_FETCHING';
