import React from 'react';
import { get } from 'lodash';
import api from 'actions/api';
import * as types from '../constants/types/accountActionTypes';
import CreateUserForm from '../containers/Accounts/Detail/UserForm/CreateUserForm';
import EditUserForm from '../containers/Accounts/Detail/UserForm/EditUserForm';

export const getAccounts = () =>
  api.postWithAuth({
    endpoint: 'masteraccount/masterAccounts.json.internal',
    baseType: types.GET_ACCOUNTS,
    success: (state, { response }) => {
      const { accounts, nextPageToken } = response.result;
      return {
        accounts,
        nextPageToken,
      };
    },
    failure: (state, error) => ({ error }),
  });

export const getAccountsByPagination = nextPageToken =>
  api.postWithAuth({
    endpoint: 'masteraccount/masterAccounts.json.internal',
    data: {
      nextPageToken,
    },
    baseType: types.GET_ACCOUNTS_BY_PAGINATION,
    success: (state, { response }) => {
      const { accounts, nextPageToken: newNextPageToken } = response.result;
      return {
        accounts,
        nextPageToken: newNextPageToken,
      };
    },
    failure: (state, error) => ({ error }),
  });

export const selectAccount = maid => ({
  type: types.SELECT_ACCOUNT,
  maid,
});

export const deselectAccount = () => ({
  type: types.SELECT_ACCOUNT,
  maid: null,
});

export const updateSubdomain = (maid, subdomain, ticket) =>
  api.postWithAuth({
    endpoint: 'masteraccount/changeSubdomain.json.internal',
    data: {
      maid,
      subdomain,
      ticket,
    },
    baseType: types.UPDATE_SUBDOMAIN,
    success: () => ({ maid, subdomain }),
    failure: (state, error) => ({ error }),
  });

export const getFeaturesForAccount = maid =>
  api.postWithAuth({
    endpoint: 'featureFlags.json.internal',
    data: {
      maid,
    },
    baseType: types.GET_FEATURES,
    success: (state, { response: { result } }) => result,
    failure: (state, error) => ({ error }),
  });

export const toggleFeature = featureName => ({
  type: types.TOGGLE_FEATURE,
  featureName,
});

export const saveFeatureChanges = (maid, ticket, flagsToChange) =>
  api.postWithAuth({
    endpoint: 'upsertFeatureFlags.json.internal',
    data: {
      maid,
      ticket,
      flagsToChange,
    },
    baseType: types.SAVE_FEATURE_CHANGES,
    success: (state, { response }) => response,
    failure: (state, error) => ({ error }),
  });

export const getUsers = maid =>
  api.postWithAuth({
    endpoint: 'users/list.json.internal',
    data: {
      maid,
      withPagination: false,
    },
    baseType: types.GET_USERS,
    success: (state, { response }) => response.result,
    failure: (state, error) => ({ error }),
  });

export const getCredits = maid =>
  api.postWithAuth({
    endpoint: 'credits/getCredits.json.internal',
    data: {
      maid,
    },
    baseType: types.GET_CREDITS,
    success: (state, { response }) => response.result,
    failure: (state, error) => ({ error }),
  });

export const updateUserCredits = (maid, fullUserCredits, limitedUserCredits, ticket) =>
  api.postWithAuth({
    endpoint: 'credits/upsertCredits.json.internal',
    data: {
      credits: {
        maid,
        fullUserCredits,
        limitedUserCredits,
      },
      ticket,
    },
    baseType: types.UPDATE_CREDITS,
    success: (state, { response }) => response.result,
    failure: (_, error) => {
      const msg = get(error, 'xhr.response.message', 'Something went wrong');
      alert(msg); // eslint-disable-line
      return { error };
    },
  });

export const regeneratePdf = (maid, submissionId, ticket) =>
  api.postWithAuth({
    endpoint: 'submission/regeneratePdf.json.internal',
    data: { maid, submissionId, ticket },
    baseType: types.REGENERATE_PDF,
    success: (state, { response }) => {
      alert('Successfully regenerated'); //eslint-disable-line
      return response.result;
    },
    failure: (_, error) => {
      const msg = get(error, 'xhr.response.message', 'Something went wrong');
      console.log(error); //eslint-disable-line
      alert("Error: " + msg); //eslint-disable-line
      return { error };
    },
  });

export const selectUser = userId => ({
  type: types.SELECT_USER,
  userId,
});

export const openCreateUserModal = maid => ({
  type: types.SET_USER_MODAL,
  modal: <CreateUserForm maid={maid} />,
});

export const openEditUserModal = maid => ({
  type: types.SET_USER_MODAL,
  modal: <EditUserForm maid={maid} />,
});

export const closeUserModal = () => ({
  type: types.SET_USER_MODAL,
  modal: null,
});

export const createUser = (maid, user, ticket) =>
  api.postWithAuth({
    endpoint: 'createUser.json.internal',
    data: {
      maid,
      metadata: {
        role: user.role,
        isActive: user.isActive,
      },
      profile: {
        userData: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        },
        password: user.password,
      },
      ticket,
    },
    baseType: types.CREATE_USER,
    success: (_, { response }) => ({ user: { ...user, uid: response.result.uid } }),
    failure: (_, error) => {
      const msg = get(error, 'xhr.response.message', 'Something went wrong');
      alert(msg); // eslint-disable-line
      return { error };
    },
  });

const updateUser = (maid, user, ticket) =>
  api.postWithAuth({
    endpoint: 'updateUser.json.internal',
    data: {
      maid,
      targetUid: user.uid,
      metadata: {
        role: user.role,
        isActive: user.isActive,
      },
      profile: {
        userData: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        },
        password: user.password,
      },
      ticket,
    },
    baseType: types.UPDATE_USER,
    success: () => ({ user }),
    failure: (_, error) => {
      const msg = get(error, 'xhr.response.message', 'Something went wrong');
      alert(msg); // eslint-disable-line
      return { error };
    },
  });

export const updateAndTransferUser = (
  maid,
  user,
  ticket,
  transferUserId,
  dispatch
) => {
  if (!transferUserId) {
    return updateUser(maid, user, ticket);
  }

  return api.postWithAuth({
    endpoint: 'forms/changeAuthor.json.internal',
    data: {
      maid,
      previousAuthorId: user.uid,
      newAuthorId: transferUserId,
    },
    baseType: types.TRANSFER_USER_DATA,
    success: () => {
      dispatch(updateUser(maid, user, ticket));
    },
    failure: (_, error) => {
      const msg = get(error, 'xhr.response.message', 'Something went wrong');
      alert(msg); // eslint-disable-line
      return { error };
    },
  });
};

export const resetFetching = () => ({
  type: types.RESET_FETCHING,
});
