import * as Colors from 'utils/colors';

export default {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
    padding: '0 36px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .15)',
    minHeight: 56,
  },
  logo: {
    marginRight: 16,
  },
  logoToolbar: {
    maxHeight: '66px',
    marginLeft: 0,
    display: 'flex',
  },
  lastToolbar: {
    marginRight: 0,
  },
  toolbarTitle: {
    color: Colors.textPrimary,
  },
  accountToolbar: {
    height: '65px',
    width: '100%',
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.grey300}`,
    boxShadow: 'none',
  },
  createAccountBtn: {
    marginRight: '15px',
  },
};
