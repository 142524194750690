import api from 'actions/api';

import { getAccounts } from './accounts';

import * as types from '../constants/types/masterAccountTypes';


export const openCreateMasterAccountModal = () => ({
  type: types.OPEN_CREATE_MASTER_ACCOUNT_MODAL,
});

export const closeCreateMasterAccountModal = () => ({
  type: types.CLOSE_CREATE_MASTER_ACCOUNT_MODAL,
});

export const setErrorInfo = msg => ({
  type: types.SET_CREATE_MASTER_ACCOUNT_ERROR_INFO,
  payload: {
    msg,
  },
});

export const clearErrorInfo = () => ({
  type: types.CLEAR_CREATE_MASTER_ACCOUNT_ERROR_INFO,
});

export const createMasterAccount = (model, dispatch) =>
  api.postWithAuth({
    endpoint: 'masteraccount/create.json.internal',
    baseType: types.CREATE_MASTER_ACCOUNT,
    data: {
      data: {
        masterAccountData: {
          subdomain: model.subdomain,
          companyEmail: model.organizationEmail,
          companyName: model.organizationName,
        },
        userDataWithPassword: {
          password: model.password,
          userData: {
            email: model.email,
            firstName: model.firstName,
            lastName: model.lastName,
          },
        },
      },
    },
    success: () => {
      dispatch(closeCreateMasterAccountModal());
      dispatch(getAccounts());
    },
    failure: (state, error) => {
      // $FlowIgnore:
      dispatch(setErrorInfo(error?.xhr?.response?.response));

      return { error };
    },
  });
