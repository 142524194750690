import api from 'reducers/api';

import {featuresEnum} from 'internal-tools/constants/features';
import * as types from '../../constants/types/accountActionTypes';


const initialState = Object.keys(featuresEnum).reduce((accum, feature) => {
  accum[feature] = {flag: feature, description: '', value: false}; // shape of features coming from backend
  return accum;
}, {});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FEATURES:
      const getFeatures = action;
      return api(action, state, {
        pending: () => state,
        success: () => {
          if (getFeatures.status === 'success') {
            return getFeatures.payload.flags.reduce((accum, feature) => {
              accum[feature.flag] = feature;
              return accum;
            }, {});
          }
          return state;
        },
        failure: () => state,
      });
    case types.TOGGLE_FEATURE:
      const {featureName} = action;
      return {
        ...state,
        [featureName]: {...state[featureName], value: !state[featureName].value},
      };
    case types.SELECT_ACCOUNT:
      return action.maid ? state : initialState;
    case types.SAVE_FEATURE_CHANGES:
    default:
      return state;
  }
};
