import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import styles from 'internal-tools/styles/table.css';

export default class AccountTableHeaderRow extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TableRow classes={{ root: styles.accountHeaderRow }}>
          <TableCell variant='head' classes={{ root: styles.accountMainHeaderColumn }}>
            User accounts
          </TableCell>
        </TableRow>
        <TableRow classes={{ root: `${styles.accountRow} ${styles.accountHeaderRow}` }}>
          <TableCell variant='head' classes={{ root: styles.accountHeaderLeftColumn }}>
            ACCOUNT
          </TableCell>
          <TableCell variant='head' classes={{ root: styles.accountHeaderRightColumn }}>
            TYPE
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}
