import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SubmitButton from 'internal-tools/components/SubmitButton';
import * as actions from 'internal-tools/actions/accounts';
import accountStyles from 'internal-tools/styles/accounts.css';

const RELEVANT_COMPONENT = 'features';
export class FeatureFlags extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { ticket: '', dirty: false };
  }

  componentDidUpdate() {
    const { fetching: { component, status } } = this.props;
    if (component === RELEVANT_COMPONENT && status !== 'pending') {
      setTimeout(() => {
        this.props.resetFetching();
        this.setState({ ticket: '', dirty: false });
      }, 10);
    }
  }

  getFeatureTogglesGroups = features => {
    const group1 = [
      features.find(fe => fe.flag === 'VISITOR_USER_SELF_REGISTRATION_SUPPORT'),
      features.find(fe => fe.flag === 'VISITOR_ACCESS_SUPPORT'),
      features.find(fe => fe.flag === 'SAVE_AND_CONTINUE'),
    ];

    const group2 = [
      features.find(fe => fe.flag === 'SUBMISSION_DETAILS_FULL_VIEW'),
      features.find(fe => fe.flag === 'GRM_WITHOUT_SC'),
      features.find(fe => fe.flag === 'GRM_WITH_SC'),
    ];

    const group3 = [
      features.find(fe => fe.flag === 'QUICK_FILTERS_SUPPORT'),
      features.find(fe => fe.flag === 'STAGES_IN_SUBMISSION_MANAGER_SUPPORT'),
      features.find(fe => fe.flag === 'TAGS_IN_SUBMISSION_MANAGER_SUPPORT'),
      features.find(fe => fe.flag === 'ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT'),
      features.find(fe => fe.flag === 'SUBMISSION_RULE_BUILDER_TRIGGERS_SUPPORT'),
      features.find(fe => fe.flag === 'AUTOMATED_PROCESS_BUILDER_SUPPORT'),
    ];

    const group4 = [
      features.find(fe => fe.flag === 'SPREEDLY_SUPPORT'),
      features.find(fe => fe.flag === 'SSO_LOGIN_ONLY'),
      features.find(fe => fe.flag === 'COUNTY_FUSION_APPLICATION_ID'),
    ];

    const group5 = [
      features.find(fe => fe.flag === 'DISABLE_DOC_UPLOAD'),
      features.find(fe => fe.flag === 'LIMIT_TO_WCAG_ELEMENTS'),
    ];

    const group6 = [
      features.find(fe => fe.flag === 'FORTE_CHECKOUT_PAYMENT'),
      features.find(fe => fe.flag === 'SIGNALAYER_TOUR_SUPPORT'),
      features.find(fe => fe.flag === 'DO_NOT_USE_NEW_LIVEVIEW_THEME_AS_DEFAULT'),
      features.find(fe => fe.flag === 'PaymentElavon'),
      features.find(fe => fe.flag === 'PaymentInvoiceCloud'),
      features.find(fe => fe.flag === 'PaymentInvoiceCloudPlugin'),
      features.find(fe => fe.flag === 'NIC_PAYMENT'),
      features.find(fe => fe.flag === 'MUNICIPAY'),
      features.find(fe => fe.flag === 'GOVOS_PAY'),
      features.find(fe => fe.flag === 'ID_VERIFICATION'),
      features.find(fe => fe.flag === 'WEBFORM_NG_LIVE_VIEW'),
    ];

    return [group1, group2, group3, group4, group5, group6];
  }

  buildFeatureToggles() {
    const groups = this.props.features && this.getFeatureTogglesGroups(this.props.features);
    return groups.map(group => (
      <>
        {group.map(feature => {
          const {
            featureLabel,
            featureLabelPlacementStart,
            featureSwitch,
            featureIsChecked,
            featureThumbStyle,
            featureThumbSwitchedStyle,
            featureBarStyle,
          } = accountStyles;
          return feature &&
             (
               <FormControlLabel
                 classes={{
                   label: featureLabel,
                   /* @ts-ignore */
                   labelPlacementStart: featureLabelPlacementStart,
                 }}
                 control={
                   <Switch
                     classes={{
                       root: featureSwitch,
                       checked: featureIsChecked,
                       icon: featureThumbStyle,
                       iconChecked: featureThumbSwitchedStyle,
                       bar: featureBarStyle,
                     }}
                     color={'default'}
                     checked={feature.value}
                     onChange={this.handleFeatureToggle}
                     name={feature.flag}/>
                 }
                 key={feature.flag}
                 label={feature.description}
                 labelPlacement='start'/>);
        })}
        <FormControlLabel
          classes={{
            label: accountStyles.featureLabel,
            /* @ts-ignore */
            labelPlacementStart: accountStyles.featureLabelPlacementStart,
          }}
          control={<span/>}
          label={''}
          labelPlacement='start'/>
      </>
    ));
  }

  showErrorText() {
    if (this.state.dirty && !this.state.ticket) return 'This field is required.';
    return '';
  }

  ableToSubmit() {
    return this.state.dirty && Boolean(this.state.ticket);
  }

  handleFeatureToggle = event => {
    const { target: { name } } = event;
    this.props.toggleFeature(name);
    this.setState({ dirty: true });
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.ableToSubmit()) {
      this.props.saveFeatureChanges(
        this.props.selectedAccountMAID,
        this.state.ticket,
        this.props.features
      );
    }
  }

  handleTicketChange = event => {
    this.setState({ ticket: event.target.value });
  }

  render() {
    const { fetching: { component, status } } = this.props;

    const {
      featureContainer,
      featureHeader,
      featureSubmit,
      featureSubmitGroup,
      ticketInput,
      ticketInputGroup,
      featureLabelPlacementStart,
      ticketInputLabel,
    } = accountStyles;

    return (
      <form className={featureContainer} onSubmit={this.handleSubmit}>
        <FormControlLabel
          classes={{
            label: featureHeader,
            /* @ts-ignore */
            labelPlacementStart: featureLabelPlacementStart,
          }}
          control={<span className={featureHeader}>Enable Feature</span>}
          label='Available Features'
          labelPlacement='start' />
        {this.buildFeatureToggles()}
        <div className={`${featureSubmitGroup} ${featureSubmit}`}>
          <div className={`${featureSubmit} ${ticketInputGroup}`}>
            <label htmlFor='jira-ticket' className={ticketInputLabel}>JUSTIFICATION</label>
            <input className={ticketInput} value={this.state.ticket} onChange={this.handleTicketChange} placeholder='Jira Ticket #' />
          </div>
          <SubmitButton disabled={!this.ableToSubmit()} isFetching={component === RELEVANT_COMPONENT} status={status} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  features: Object.values(state.accounts.features),
  selectedAccountMAID: state.accounts.ui.selectedAccountMAID,
  fetching: state.accounts.ui.fetching,
});

const { getFeaturesForAccount, toggleFeature, saveFeatureChanges, resetFetching } = actions;

const mapDispatchToProps = dispatch => bindActionCreators({
  getFeaturesForAccount,
  saveFeatureChanges,
  toggleFeature,
  resetFetching,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeatureFlags);
