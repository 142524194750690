import api from 'reducers/api';
import * as types from 'internal-tools/constants/types/accountActionTypes';

const initialState = {};
export default function accountsReducer(state = initialState, action_) {
  const newAccounts = {};
  const action = action_;
  switch (action.type) {
    case types.GET_ACCOUNTS:
      return api(action, state, {
        pending: () => state,
        success: () => {
          if (action.status === 'success') {
            action.payload.accounts.forEach(acc => {newAccounts[acc.maid] = acc;});
            return {
              ...state,
              ...newAccounts,
            };
          }
          return state;
        },
        failure: () => {
          if (action.status === 'failure') {
            return initialState;
          }
          return state;
        },
      });
    case types.GET_ACCOUNTS_BY_PAGINATION:
      return api(action, state, {
        pending: () => state,
        success: () => {
          if (action.status === 'success') {
            action.payload.accounts.forEach(acc => {newAccounts[acc.maid] = acc;});
            return {
              ...state,
              ...newAccounts,
            };
          }
          return state;
        },
        failure: () => state,
      });
    case types.UPDATE_SUBDOMAIN:
      return api(action, state, {
        pending: () => state,
        success: () => {
          if (action.status === 'success') {
            const oldAccount = state[action.payload.maid];
            return {
              ...state,
              [oldAccount.maid]: {...oldAccount, subdomain: action.payload.subdomain},
            };
          }
          return state;
        },
        failure: () => state,
      });
    default:
      return state;
  }
}
