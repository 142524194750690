import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DisplayResultsTable from 'internal-tools/components/DisplayResultsTable';
import createAccountTableRowFactory from 'internal-tools/components/AccountTableRow';
import AccountTableHeaderRow from 'internal-tools/components/AccountTableHeaderRow';

import * as actions from 'internal-tools/actions/accounts';
import containerStyles from 'styles/shared/containerStyles.css';
import accountStyles from 'internal-tools/styles/accounts.css';


// DisplayResultsTable requires a tableHeight prop for pagination purposes
// It uses 50px as its boundary for triggering another fetch
const TABLE_HEIGHT = 50;

class Accounts extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }
  componentDidMount() {
    this.props.actions.getAccounts();
  }

  selectAccount = maid => () => this.props.actions.selectAccount(maid)

  onGetAccountsByPagination = () => {
    const { ui: { nextPageToken } } = this.props;
    if (nextPageToken) {
      this.props.actions.getAccountsByPagination(nextPageToken);
    }
  }

  onSearchChanged = event => {
    this.setState({
      query: event.target.value,
    });
  }

  render() {
    const {
      accounts,
      ui: {
        loadingTableResults,
        loadingMoreTableResults,
      },
    } = this.props;

    const filteredAccounts = accounts
      .filter(a => a.subdomain.includes(this.state.query.trim())) || [];

    return (
      <div className={`${containerStyles.Container} ${accountStyles.accountList}`}>
        <div>
          <input
            className={containerStyles.SearchInput}
            type='search'
            onChange={this.onSearchChanged}
            placeholder='Search for accounts...'
            autoComplete='search'
          />
        </div>
        <DisplayResultsTable
          buildSingleRow={createAccountTableRowFactory(this.selectAccount, this.props.ui.selectedAccountMAID)}
          getResultsByPagination={this.onGetAccountsByPagination}
          loading={loadingTableResults}
          loadingMore={loadingMoreTableResults}
          results={filteredAccounts}
          tableHeaderRow={AccountTableHeaderRow}
          tableHeight={TABLE_HEIGHT} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accounts: Object.values(state.accounts.allAccounts),
  ui: state.accounts.ui,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
