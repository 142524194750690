import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { COMPANY_NAME } from 'constants/applicationCopy';
import brandLogo from 'images/brand-logo.svg';

import sharedStyles from 'internal-tools/styles/sharedInlineStyles';


export default class Header extends React.PureComponent<any, any> {
  render() {
    const { isAuthenticated, onRequestLogoutClick, onCreateAccountClick } = this.props;

    return (
      <Toolbar style={{ ...sharedStyles.toolbar, ...sharedStyles.accountToolbar }}>
        <div style={sharedStyles.logoToolbar}>
          <h2 style={sharedStyles.toolbarTitle}>{COMPANY_NAME}</h2>
          <img alt='' src={brandLogo} style={sharedStyles.logo} />
        </div>
        {isAuthenticated && (
          <div>
            <Button
              style={sharedStyles.createAccountBtn}
              color='primary'
              variant='contained'
              /* @ts-ignore */
              label='Create Master Account'
              onClick={onCreateAccountClick}
            >
              Create Master Account
            </Button>
            {/* @ts-ignore */}
            <Button label='Sign Out' onClick={onRequestLogoutClick}>
              Sign Out
            </Button>
          </div>
        )}
      </Toolbar>
    );
  }
}
