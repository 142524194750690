import * as types from 'constants/types/authActionTypes';
import {receiveLogout} from 'actions/auth';
import {signOutOfInternalTooling} from 'utils/auth';

export default function logoutEpic(
  action$,
) {
  return action$
    .ofType(types.LOGOUT_REQUEST)
    .do(signOutOfInternalTooling)
    .map(receiveLogout);
}
