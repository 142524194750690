let configStore = null;
if (process.env.NODE_ENV === 'production') {
  configStore = require('./prodConfig');
} else {
  configStore = require('./devConfig');
}

// @ts-ignore
const store = configStore.default();

export default store;
