import React from 'react';
import AccountList from './List';
import AccountDetail from './Detail';
import styles from 'internal-tools/styles/accounts.css';

export default class AccountsPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <AccountList />
        <div className={styles.border} />
        <AccountDetail />
      </React.Fragment>
    );
  }
}
