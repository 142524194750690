import api from 'reducers/api';
import * as types from 'internal-tools/constants/types/accountActionTypes';


const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS:
      return api(action, state, {
        pending: () => state,
        success: () => {
          if (action.status === 'success') {
            return action.payload.users.reduce((newState, user) => {
              newState[user.uid] = user;
              return newState;
            }, {});
          }
          return state;
        },
        failure: () => state,
      });
    case types.UPDATE_USER:
    case types.CREATE_USER:
      const updateUser = action;
      return api(action, state, {
        pending: () => state,
        success: () => {
          if (action.status === 'success') {
            return {
              ...state,
              [updateUser.payload.user.uid]: updateUser.payload.user,
            };
          }
          return state;
        },
        failure: () => state,
      });
    default:
      return state;
  }
};
