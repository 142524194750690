import * as types from '../constants/types/masterAccountTypes';


const initialState = {
  isCreateModalOpen: false,
  isValidRequest: true,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_CREATE_MASTER_ACCOUNT_MODAL:
      return { ...state, isCreateModalOpen: true, isValidRequest: true, errorMessage: '' };
    case types.CLOSE_CREATE_MASTER_ACCOUNT_MODAL:
      return { ...state, isCreateModalOpen: false, isValidRequest: true, errorMessage: '' };
    case types.SET_CREATE_MASTER_ACCOUNT_ERROR_INFO:
      return { ...state, isValidRequest: false, errorMessage: action.payload.msg };
    case types.CLEAR_CREATE_MASTER_ACCOUNT_ERROR_INFO:
      return { ...state, isValidRequest: true, errorMessage: '' };
    default:
      return state;
  }
};
