export const featuresEnum = {
  USE_NEW_LIVEVIEW_THEME_AS_DEFAULT: 'USE_NEW_LIVEVIEW_THEME_AS_DEFAULT',
  LIMIT_TO_WCAG_ELEMENTS: 'LIMIT_TO_WCAG_ELEMENTS',
  SAVE_AND_CONTINUE: 'SAVE_AND_CONTINUE',
  PaymentElavon: 'PaymentElavon',
  PaymentInvoiceCloud: 'PaymentInvoiceCloud',
  PaymentInvoiceCloudPlugin: 'PaymentInvoiceCloudPlugin',
  SUBMISSION_RULE_BUILDER_TRIGGERS_SUPPORT: 'SUBMISSION_RULE_BUILDER_TRIGGERS_SUPPORT',
  DISABLE_DOC_UPLOAD: 'DISABLE_DOC_UPLOAD',
};
