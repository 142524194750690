import React from 'react';
import { connect } from 'react-redux';

import Icon from '@material-ui/core/Icon';
import FeatureFlags from './FeatureFlags';
import UserCredits from './UserCredits';
import UserList from './UserList';
import UpdateSubdomainForm from './UpdateSubdomainForm';
import {
  deselectAccount,
  getUsers,
  getCredits,
  updateUserCredits,
  getFeaturesForAccount,
  resetFetching,
  updateSubdomain,
  regeneratePdf,
} from 'internal-tools/actions/accounts';
import accountStyles from 'internal-tools/styles/accounts.css';
import * as Colors from 'utils/colors';
import PdfRegenerationForm from './PdfRegeneration';
export class AccountDetail extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.closeDetail);
  }
  componentDidUpdate(prevProps) {
    const {
      account,
      fetching: { component, status },
    } = this.props;
    if (account.maid && account.maid !== prevProps.account.maid) {
      this.props.getFeaturesForAccount(account.maid);
      this.props.getUsers(account.maid);
      this.props.getCredits(account.maid);
      return;
    }
    if (component === 'subdomain' && status !== 'pending') {
      setTimeout(() => {
        this.props.resetFetching();
        this.setState({ editing: false });
      }, 2500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closeDetail);
  }

  toggleEditingState(state) {
    return event => {
      event.stopPropagation();
      this.setState({ editing: state });
    };
  }

  selectAccountReminder() {
    const { selectAccountIcon, selectAccountReminder } = accountStyles;
    return (
      <div className={selectAccountReminder}>
        <p>Select an account to edit</p>
        <div className={selectAccountIcon}>
          <Icon style={{ color: Colors.grey300 }}>arrow_back</Icon>
        </div>
      </div>
    );
  }

  updateUserCredits = (fullUserCredits, limitedUserCredits, ticket) => {
    this.props.updateUserCredits(this.props.account.maid, fullUserCredits, limitedUserCredits, ticket);
  };
  regeneratePdf = (submissionId, ticket) => {
    this.props.regeneratePdf(this.props.account.maid, submissionId, ticket);
  };

  subdomainEditor() {
    const { account, fetching } = this.props;
    if (this.state.editing) {
      return (
        <UpdateSubdomainForm
          fetching={fetching}
          maid={account.maid}
          subdomain={account.subdomain}
          updateSubdomain={this.props.updateSubdomain}
        />
      );
    }
    const { editSubdomainButton, headerDetailTitle } = accountStyles;
    return (
      <React.Fragment>
        <span className={headerDetailTitle}>{account.subdomain}</span>
        <button className={editSubdomainButton} onClick={this.toggleEditingState(true)}>
          <Icon style={{ color: Colors.grey300, fontSize: '16px' }}>{'create'}</Icon>
        </button>
      </React.Fragment>
    );
  }

  content() {
    const { accountDetail, headerDetail, overlay } = accountStyles;

    return (
      <div className={overlay} onClick={this.props.goBack}>
        <div className={accountDetail} onClick={this.toggleEditingState(false)}>
          <header className={headerDetail}>{this.subdomainEditor()}</header>
          <FeatureFlags />

          {!this.props.credits.isLoading && (
            <UserCredits
              fullUserCredits={this.props.credits.fullUserCredits}
              limitedUserCredits={this.props.credits.limitedUserCredits}
              updateCredits={this.updateUserCredits}
              isUpdating={this.props.credits.isUpdating}
            />
          )}
          <PdfRegenerationForm regeneratePdf={this.regeneratePdf}/>
          <UserList />
        </div>
      </div>
    );
  }

  closeDetail = event => {
    if (event.key === 'Escape') this.props.goBack();
  };

  render() {
    return this.props.account.maid ? this.content() : this.selectAccountReminder();
  }
}

const mapStateToProps = state => {
  const account = state.accounts.allAccounts[state.accounts.ui.selectedAccountMAID] || { maid: '' };

  return {
    account,
    fetching: state.accounts.ui.fetching,
    credits: state.accounts.credits,
  };
};

const mapDispatchToProps = dispatch => ({
  getFeaturesForAccount: maid => dispatch(getFeaturesForAccount(maid)),
  getUsers: maid => dispatch(getUsers(maid)),
  getCredits: maid => dispatch(getCredits(maid)),
  updateUserCredits: (maid, fullUserCredits, limitedUserCredits, ticket) =>
    dispatch(updateUserCredits(maid, fullUserCredits, limitedUserCredits, ticket)),
  regeneratePdf: (maid, submissionId, ticket) =>
    dispatch(regeneratePdf(maid, submissionId, ticket)),
  goBack: () => dispatch(deselectAccount()),
  resetFetching: () => dispatch(resetFetching()),
  updateSubdomain: (maid, subdomain, ticket) =>
    dispatch(updateSubdomain(maid, subdomain, ticket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail);
