import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import * as actions from 'internal-tools/actions/accounts';
import accountStyles from 'internal-tools/styles/accounts.css';
import tableStyles from 'internal-tools/styles/table.css';
import * as Colors from 'utils/colors';
import { formatUserName } from 'internal-tools/utils/users';
import { userRolesEnum, LimitedUserRoles } from 'constants/user';

export class UserList extends React.Component<any, any> {
  buildRows() {
    const checkboxStyle = {
      color: Colors.white,
      verticalAlign: 'middle',
      marginRight: '5px',
    };

    return this.props.users.map(user => (
      <TableRow key={user.uid} classes={{ root: tableStyles.userRow }} onClick={this.selectUser(user.uid)}>
        <TableCell classes={{ root: tableStyles.userCell }}>{user.email}</TableCell>
        <TableCell classes={{ root: tableStyles.userCell }}>{formatUserName(user)}</TableCell>
        <TableCell classes={{ root: tableStyles.userCell }}>
          <Icon style={checkboxStyle}>{user.isActive ? 'check_box' : 'check_box_outline_blank'}</Icon>
          <span>Enabled</span>
        </TableCell>
        <TableCell classes={{ root: tableStyles.userCell }}>{user.role}</TableCell>
      </TableRow>
    ));
  }

  content() {
    const { users } = this.props;
    if (users.length) {
      const rows = this.buildRows();

      return (
        <Table>
          <TableBody>{rows}</TableBody>
        </Table>
      );
    }
    return <p>No users found.</p>;
  }

  selectUser = uid => () => {
    this.props.selectUser(uid);
    this.props.openEditUserModal(this.props.selectedAccountMAID);
  };

  openCreateUserModal = () => {
    this.props.openCreateUserModal(this.props.selectedAccountMAID);
  };

  render() {
    const { modal, users } = this.props;

    const activeUsers = users.filter(u => u.isActive);

    const visitorsCount = activeUsers.filter(u => u.role === userRolesEnum.VISITOR).length;
    const limitedUsersCount = activeUsers.filter(u => LimitedUserRoles.includes(u.role)).length;

    return (
      <div className={accountStyles.userContainer}>
        {modal}
        <header className={accountStyles.userListHeader}>
          <p>
            Users(
            {`${activeUsers.length -
              limitedUsersCount -
              visitorsCount} full, ${limitedUsersCount} limited, ${visitorsCount} free`}
            )
          </p>
          <button className={accountStyles.addUserButton} onClick={this.openCreateUserModal}>
            <p className={accountStyles.addUserButtonLabel}>Add User</p>
            <div className={accountStyles.addUserInnerButton}>
              <Icon style={{ color: Colors.white, fontSize: '14px', margin: '0 0 -1px -0.5px' }}>add</Icon>
            </div>
          </button>
        </header>
        {this.content()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedAccountMAID: state.accounts.ui.selectedAccountMAID,
  users: Object.values(state.accounts.users),
  modal: state.accounts.ui.modal,
});

const { getUsers, selectUser, openCreateUserModal, openEditUserModal } = actions;

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUsers, selectUser, openCreateUserModal, openEditUserModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
