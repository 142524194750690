import api from 'reducers/api';


import * as types from '../../constants/types/accountActionTypes';


const initialState = {
  isLoading: false,
  isUpdating: false,
  fullUserCredits: null,
  limitedUserCredits: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CREDITS:

      return api(action, state, {
        pending: () => ({...state, isLoading: true}),
        success: () => {
          if (action.status === 'success') {
            return {
              ...action.payload,
              isLoading: false,
            };
          }

          return { ...state, isLoading: false };
        },
        failure: () => ({ ...state, isLoading: false }),
      });
    case types.UPDATE_CREDITS:
      return api(action, state, {
        pending: () => ({...state, isUpdating: true}),
        success: () => {
          if (action.status === 'success') {
            return {
              ...action.payload,
              isUpdating: false,
            };
          }

          return { ...state, isUpdating: false };
        },
        failure: () => ({ ...state, isUpdating: false }),
      });
    default:
      return state;
  }
};
