import React from 'react';
import _ from 'lodash';

import SubmitButton from 'internal-tools/components/SubmitButton';
import accountStyles from 'internal-tools/styles/accounts.css';
import sharedStyles from 'internal-tools/styles/shared.css';


export default class UserCreditsForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      fullUserCredits: props.fullUserCredits,
      limitedUserCredits: props.limitedUserCredits,
      ticket: '',
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    const { fullUserCredits, limitedUserCredits, ticket } = this.state;

    this.props.updateCredits(+fullUserCredits, +limitedUserCredits, ticket);
  };

  handleTicketChange = event => {
    this.setState({ ticket: event.target.value });
  };

  handleFullUserCreditsChange = event => {
    if (!event.target.value) {
      this.setState({ fullUserCredits: null });
      return;
    }

    this.setState({ fullUserCredits: +event.target.value });
  };

  handleLimitedUserCreditsChange = event => {
    if (!event.target.value) {
      this.setState({ limitedUserCredits: null });
      return;
    }

    this.setState({ limitedUserCredits: +event.target.value });
  };

  render() {
    const {
      ticketInput,
      userCreditsContainer,
      userCreditInputLabel,
      userCreditInputContainer,
      userCreditForm,
    } = accountStyles;

    return (
      <div className={userCreditsContainer}>
        <p>User Credits</p>
        <div>
          <form className={userCreditForm} onClick={e => e.stopPropagation()} onSubmit={this.handleSubmit}>
            <div className={userCreditInputContainer}>
              <label htmlFor='full-user-credits' className={userCreditInputLabel}>
                full-user credits
              </label>
              <input
                id='full-user-credits'
                className={ticketInput}
                onChange={this.handleFullUserCreditsChange}
                value={this.state.fullUserCredits}
                type='number'
              />
            </div>

            <div className={userCreditInputContainer}>
              <label htmlFor='limited-user-credits' className={userCreditInputLabel}>
                limited-user credits
              </label>
              <input
                id='limited-user-credits'
                className={ticketInput}
                onChange={this.handleLimitedUserCreditsChange}
                value={this.state.limitedUserCredits}
                type='number'
              />
            </div>

            <div className={userCreditInputContainer}>
              <label htmlFor='credits-jira-ticket' className={userCreditInputLabel}>
                JUSTIFICATION
              </label>
              <input
                id={'credits-jira-ticket'}
                className={ticketInput}
                value={this.state.ticket}
                onChange={this.handleTicketChange}
                placeholder='Jira Ticket #'
              />
            </div>

            <SubmitButton
              classes={{ root: sharedStyles.subdomainSubmit }}
              disabled={
                !this.state.ticket.length ||
                !_.isNumber(this.state.fullUserCredits) ||
                !_.isNumber(this.state.limitedUserCredits)
              }
              isFetching={this.props.isUpdating}
              status={'pending'}
            />
          </form>
        </div>
      </div>
    );
  }
}
