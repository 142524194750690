import { connect } from 'react-redux';
import UserForm from './';
import * as actions from 'internal-tools/actions/accounts';


import { FullUserRoles } from 'constants/user';


const mapStateToProps = state => {
  const editableUser: any = state.accounts.users[state.accounts.ui.selectedUserId] || { uid: '' };

  return {
    user: editableUser,
    passwordOptional: true,
    users: Object.values(state.accounts.users).filter(
      (user: any) => user.uid !== editableUser.uid && FullUserRoles.includes(user.role),
    ),
    isUpdating: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submit: (user, ticket, transferUserId) => {
    dispatch(actions.updateAndTransferUser(ownProps.maid, user, ticket, transferUserId, dispatch));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
