import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, createMemoryHistory } from 'react-router';
import Accounts from './Accounts';
import Layout from './Layout';
import * as actions from 'actions/auth';
import { isLoggedIn } from 'utils/auth';
import Login from './Login';
import NotFoundPage from 'containers/404';

import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

// This and <JssProvider> in render allow us to change where Material UI injects its styles so we can override them
const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  // @ts-ignore
  insertionPoint: document.getElementById('jss-insertion-point'),
});

export default class Root extends React.Component<any, any> {
  authorizationCheck = (nextState, replace) => {
    const { store } = this.props;

    if (isLoggedIn()) {
      store.dispatch(actions.authorizationCheckSuccess());
    } else {
      store.dispatch(actions.authorizationCheckFailure());
      replace({
        pathname: '/login',
        state: { nextPathname: nextState.location.pathname },
      });
    }
  };
  render() {
    const { store } = this.props;
    const history = createMemoryHistory(window.location);

    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <Router history={history}>
            <Route path='/' component={Layout}>
              <IndexRoute component={Accounts} onEnter={this.authorizationCheck} />
              <Route path='login' component={Login} />
              <Route path='accounts' component={Accounts} onEnter={this.authorizationCheck} />
              <Route path='*' component={NotFoundPage} />
            </Route>
          </Router>
        </Provider>
      </JssProvider>
    );
  }
}
