import React from 'react';

import Header from './Header/header';
import CreateMasterAccountModal from '../CreateMasterAccountModal';

import styles from 'internal-tools/styles/accounts.css';


const Layout = ({
  isAuthenticated,
  onRequestLogoutClick,
  onCreateAccountClick,
  onCloseCreateMasterAccountModal,
  createMasterAccount,
  children,
  isCreateModalOpen,
  isValidCreateMasterAccountRequest,
  createMasterAccountErrorMsg,
  clearMasterAccountErrorMsg,
}) => (
  <main className={styles.accountsPage}>
    <Header
      isAuthenticated={isAuthenticated}
      onRequestLogoutClick={onRequestLogoutClick}
      onCreateAccountClick={onCreateAccountClick}
    />
    {children}

    {isCreateModalOpen && (
      <CreateMasterAccountModal
        onCloseClick={onCloseCreateMasterAccountModal}
        createMasterAccount={createMasterAccount}
        isValid={isValidCreateMasterAccountRequest}
        errorMsg={createMasterAccountErrorMsg}
        clearErrorMsg={clearMasterAccountErrorMsg}
      />
    )}
  </main>
);

export default Layout;
