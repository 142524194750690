import api from 'reducers/api';
import * as types from 'internal-tools/constants/types/accountActionTypes';


const initialState = {
  loadingMoreTableResults: false,
  loadingTableResults: false,
  nextPageToken: null,
  selectedAccountMAID: '',
  selectedUserId: '',
  modal: null,
  error: null,
  fetching: {
    component: null,
    status: null,
  },
};

const fetching = (state, action, component) => {
  const errorAction = action;
  const error = errorAction.status === 'failure' ? errorAction.error.xhr.response.message : null;
  return {
    ...state,
    error,
    fetching: {
      component,
      status: action.status,
    },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCOUNTS:
      const getAccounts = action;
      return api(action, state, {
        pending: () => ({
          ...state,
          loadingTableResults: true,
          nextPageToken: null,
        }),
        success: () => {
          if (getAccounts.status === 'success') {
            return {
              ...state,
              loadingTableResults: false,
              nextPageToken: getAccounts.payload.nextPageToken,
            };
          }
          return state;
        },
        failure: () => {
          if (getAccounts.status === 'failure') {
            return {
              ...state,
              loadingTableResults: false,
              nextPageToken: null,
            };
          }
          return state;
        },
      });
    case types.GET_ACCOUNTS_BY_PAGINATION:
      const getAccountsByPagination = action;
      return api(action, state, {
        pending: () => ({
          ...state,
          loadingMoreTableResults: true,
        }),
        success: () => {
          if (getAccountsByPagination.status === 'success') {
            return {
              ...state,
              nextPageToken: getAccountsByPagination.payload.nextPageToken,
              loadingMoreTableResults: false,
            };
          }
          return state;
        },
        failure: () => {
          if (getAccountsByPagination.status === 'failure') {
            return {
              ...state,
              loadingMoreTableResults: false,
              nextPageToken: null,
            };
          }
          return state;
        },
      });
    case types.SELECT_ACCOUNT:
      return {...state, selectedAccountMAID: action.maid};
    case types.SELECT_USER:
      return {...state, selectedUserId: action.userId};
    case types.SET_USER_MODAL:
      return {...state, modal: action.modal, error: null, fetching: initialState.fetching};
    case types.SAVE_FEATURE_CHANGES:
      return fetching(state, action, 'features');
    case types.UPDATE_SUBDOMAIN:
      return fetching(state, action, 'subdomain');
    case types.UPDATE_USER:
    case types.CREATE_USER:
      return fetching(state, action, 'users');
    case types.RESET_FETCHING:
      return {
        ...state,
        fetching: initialState.fetching,
      };
    default:
      return state;
  }
};
