import {userRolesEnum} from 'constants/user';

export const BLANK_USER = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  isActive: true,
  role: '',
  transferUserId: '',
};

export const DATABASE_BACKED_ROLES = Object.keys(userRolesEnum);
