import {combineReducers} from 'redux';

import allAccounts from './accounts';
import ui from './ui';
import features from './features';
import users from './users';
import credits from './credits';

export default combineReducers({
  allAccounts,
  ui,
  features,
  users,
  credits,
});
