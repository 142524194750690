import React from 'react';
import {Link} from 'react-router';
import * as route from 'constants/route';
import * as Colors from 'utils/colors';
import { imageDir } from 'constants/assets';
import {COMPANY_NAME} from 'constants/applicationCopy';
import Button from 'components/Button';
import brandLogoFull from 'images/brand-logo-full.svg';

export default class NotFoundPage extends React.Component {
  render() {
    const inlineStyles = {
      container: {
        backgroundColor: Colors.white,
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      },
      content: {
        maxWidth: '300px',
        marginTop: '64px',
      },
      logo: {
        width: '250px',
        marginBottom: '40px',
      },
      sadImage: {
        width: '120px',
      },
      headline: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      subHeadline: {
        color: Colors.textSecondary,
        lineHeight: '24px',
        paddingBottom: '8px',
      },
      link: {
        textDecoration: 'none',
      },
    };

    return (
      <div style={inlineStyles.container as React.CSSProperties}>
        <div style={inlineStyles.content}>
          <div>
            <img alt={COMPANY_NAME} src={brandLogoFull} style={inlineStyles.logo}/>
          </div>
          <div>
            <img alt='' src={`${imageDir}/animated-sad-document.gif`} style={inlineStyles.sadImage}/>
          </div>
          <p style={inlineStyles.headline}>This is sad for both of us</p>
          <p style={inlineStyles.subHeadline}>
            404: the page you’re looking for can&rsquo;t be found or doesn&rsquo;t exist.
          </p>
          <Link style={inlineStyles.link} to={route.ROOT}>
            <Button label='Take Me Home' primary/>
          </Link>
        </div>
      </div>
    );
  }
}
