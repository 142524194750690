import {success} from 'epics/utils/filters';
import {closeUserModal} from 'internal-tools/actions/accounts';
import {RESET_FETCHING} from 'internal-tools/constants/types/accountActionTypes';

export function closeModalOnUpsert(action$) {
  return action$
    .ofType(RESET_FETCHING)
    .filter(success)
    .map(closeUserModal);
}
