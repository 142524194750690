import * as React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import styles from 'internal-tools/styles/table.css';


export class AccountTableRow extends React.Component<any, any> {
  selectedStyling() {
    return this.props.isSelected ? styles.rowSelected : styles.accountRow;
  }

  render() {
    const { account, selectAccount } = this.props;
    return (
      <TableRow classes={{ root: this.selectedStyling() }} onClick={selectAccount}>
        <TableCell classes={{ root: styles.accountLeftCell }}>
          {account.subdomain}
        </TableCell>
        <TableCell classes={{ root: styles.accountRightCell }}>
          {account.accountType}
        </TableCell>
      </TableRow>
    );
  }
}

export default function createAccountTableRowFactory(selectAccount, selectedAccountMAID) {
  return function createAccountTableRow(account) {
    return (
      <AccountTableRow
        key={account.maid}
        account={account}
        isSelected={selectedAccountMAID === account.maid}
        selectAccount={selectAccount(account.maid)} />
    );
  };
}
