import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';


const FormSchema = Yup.object().shape({
  subdomain: Yup.string().required('Required'),
  organizationName: Yup.string(),
  organizationEmail: Yup.string().email(),

  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});


const CreateMasterAccountModal = ({ onCloseClick, createMasterAccount, isValid, errorMsg, clearErrorMsg }) => (
  <Dialog open onClose={onCloseClick}>
    <Formik
      initialValues={{
        subdomain: '',
        organizationName: '',
        organizationEmail: '',

        firstName: '',
        lastName: '',
        email: '',
        password: '',
      }}
      validationSchema={FormSchema}
      onSubmit={values => {
        createMasterAccount(values);
      }}
      validate={() => {
        clearErrorMsg();
      }}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <Form>
          <DialogTitle>Create Master Account</DialogTitle>
          <DialogContent>

            {!isValid && <div style={{ marginTop: '10px', color: 'red', fontWeight: 'bold' }}>{errorMsg}</div>}

            <Field name='subdomain'>
              {({ meta }) => (
                <TextField
                  autoFocus
                  required
                  value={values.subdomain}
                  onChange={event => setFieldValue('subdomain', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('subdomain');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='subdomain'
                  label='Subdomain'
                  type='text'
                  fullWidth
                  helperText='Some important text'
                />
              )}
            </Field>

            <Field name='organizationName'>
              {({ meta }) => (
                <TextField
                  value={values.organizationName}
                  onChange={event => setFieldValue('organizationName', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('organizationName');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='organizationName'
                  label='Organization Name'
                  type='text'
                  fullWidth
                />
              )}
            </Field>

            <Field name='organizationEmail'>
              {({ meta }) => (
                <TextField
                  value={values.organizationEmail}
                  onChange={event => setFieldValue('organizationEmail', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('organizationEmail');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='organizationEmail'
                  label='Organization Email'
                  type='text'
                  fullWidth
                />
              )}
            </Field>

            <Field name='firstName'>
              {({ meta }) => (
                <TextField
                  value={values.firstName}
                  onChange={event => setFieldValue('firstName', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('firstName');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='firstName'
                  label='First Name'
                  type='text'
                  fullWidth
                />
              )}
            </Field>

            <Field name='lastName'>
              {({ meta }) => (
                <TextField
                  value={values.lastName}
                  onChange={event => setFieldValue('lastName', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('lastName');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='lastName'
                  label='Last Name'
                  type='text'
                  fullWidth
                />
              )}
            </Field>

            <Field name='email'>
              {({ meta }) => (
                <TextField
                  required
                  value={values.email}
                  onChange={event => setFieldValue('email', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('email');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='email'
                  label='Email'
                  type='text'
                  fullWidth
                />
              )}
            </Field>

            <Field name='password'>
              {({ meta }) => (
                <TextField
                  required
                  value={values.password}
                  onChange={event => setFieldValue('password', event.target.value)}
                  onBlur={() => {
                    setFieldTouched('password');
                  }}
                  error={meta.touched && !!meta.error}
                  margin='dense'
                  id='password'
                  label='Password'
                  type='text'
                  fullWidth
                />
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseClick} color='primary'>
              Cancel
            </Button>
            <Button variant='contained' type='submit' color='primary'>
              Create
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  </Dialog>
);

export default CreateMasterAccountModal;
