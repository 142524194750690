import React from 'react';
import SubmitButton from 'internal-tools/components/SubmitButton';
import accountStyles from 'internal-tools/styles/accounts.css';
import sharedStyles from 'internal-tools/styles/shared.css';


export default class UpdateSubdomainForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { subdomain: props.subdomain, ticket: '', dirty: false };
  }

  componentDidMount() {
    (this.refs.input as any).focus();
  }

  ableToSubmit() {
    return this.state.dirty && Boolean(this.state.ticket);
  }

  handleSubmit = event => {
    event.preventDefault();
    const { subdomain, ticket } = this.state;
    if (this.ableToSubmit()) this.props.updateSubdomain(this.props.maid, subdomain, ticket);
  }

  handleTicketChange = event => {
    this.setState({ ticket: event.target.value });
  }

  handleSubdomainChange = event => {
    this.setState({ subdomain: event.target.value, dirty: true });
  }

  render() {
    const { fetching } = this.props;

    const {
      headerDetailTitle,
      subdomainEditing,
      subdomainSubmitGroup,
      ticketInput,
    } = accountStyles;

    return (
      <form onClick={e => e.stopPropagation()} onSubmit={this.handleSubmit}>
        <input
          ref='input'
          onChange={this.handleSubdomainChange}
          className={`${headerDetailTitle} ${subdomainEditing}`}
          value={this.state.subdomain} />
        <div className={subdomainSubmitGroup}>
          <input
            className={ticketInput}
            placeholder='Jira Ticket #'
            onChange={this.handleTicketChange}
            value={this.state.ticket} />
          <SubmitButton
            classes={{ root: sharedStyles.subdomainSubmit }}
            disabled={!this.ableToSubmit()}
            isFetching={fetching.component === 'subdomain'}
            status={fetching.status} />
        </div>
      </form>
    );
  }
}
