import {combineReducers} from 'redux';
import auth from 'reducers/auth';
import accounts from './accounts';
import masterAccount from './masterAccount';

export default combineReducers({
  auth,
  accounts,
  masterAccount,
});
