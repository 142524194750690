import {createStore, applyMiddleware, compose} from 'redux';
import epicMiddleware from '../middleware/epics';
import rootReducer from '../reducers';

export default function configureStore(initialState = {}) {
  return createStore(rootReducer,
    initialState,
    compose(
      applyMiddleware(epicMiddleware())
    )
  );
}
