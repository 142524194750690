export default function getErrors(properties, serverError) {
  return properties.reduce((accum, prop) => {
    if (!serverError) return accum;
    let message;
    if (typeof serverError === 'string') {
      message = serverError.toLowerCase().includes(prop) ? serverError : null;
    } else if (typeof serverError === 'object') {
      message = Object.keys(serverError).some(key => key.includes(prop)) ? `Please enter a valid value for ${prop}.` : null;
    }
    accum[prop] = message;
    return accum;
  }, {});
}
