// Adding individual operators to rxjs/Observable, per https://github.com/ReactiveX/rxjs#installation-and-usage
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/let';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/mergeAll';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/sampleTime';
import 'rxjs/add/operator/take';

import {combineEpics} from 'redux-observable';
import {closeModalOnUpsert} from './accounts';
import loginEpic from './login';
import logoutEpic from './logout';
import apiEpic from 'epics/api';


export default combineEpics(
  apiEpic,
  loginEpic,
  logoutEpic,
  closeModalOnUpsert
);
