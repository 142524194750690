import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import injectRouter from 'decorators/injectRouter';
import style from 'styles/Login.css';
import { COMPANY_NAME } from 'constants/applicationCopy';
import * as utils from 'utils/auth';
import brandLogo from 'images/brand-logo.svg';
import { Location } from 'types/sharedTypes';
import LoginForm from './LoginForm';

type Props = {
  router: { replace: (path: string) => void },
  location: Location,
  auth: any,
  dispatch: Function,
}

const Login = ({ router, location, auth, dispatch }: Props) => {
  const redirect = (path?: string) => {
    if (path) {
      router.replace(path);
      return;
    }

    if (location.state && location.state.nextPathname) {
      router.replace(location.state.nextPathname);
    } else if (location.query && location.query.state) {
      router.replace(`${location.query.state}`);
    } else {
      router.replace('/login');
    }
  };

  useEffect(() => {
    if (utils.isLoggedIn()) {
      redirect();
      return;
    }
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated && utils.isLoggedIn()) {
      redirect();
      return;
    }
  }, [auth]);

  const onSubmit = (email: string, password: string): void => {
    utils.tailorLogin(email, password, dispatch);
  };

  return (
    <div className={style.loginHolder}>
      <div className={style.loginContainer}>
        <div>
          <div className={style.header}>
            <div className={style.companyLogoWrapper}>
              <img alt={COMPANY_NAME} className={style.sdCompanyLogo} src={brandLogo} />
            </div>
          </div>
          <div className={style.body} style={{ textAlign: 'center' }}>
            <LoginForm
              onSubmit={onSubmit}
              isProcessing={false}
              error={utils.processLoginError(auth?.error)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = auth => auth;

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectRouter(Login));

