import * as React from 'react';
import { connect } from 'react-redux';
import { memoize } from 'lodash';
import getErrors from 'internal-tools/utils/errors';


const withErrors = (Component, fields, errorSelector) => {
  class ErrorHandler extends React.Component<any, any> {
    formatErrors = memoize(errorProp => getErrors(fields, errorProp))

    render() {
      const { errors, ...props } = this.props;
      const formattedErrors = this.formatErrors(errors);
      return <Component errors={formattedErrors} {...props} />;
    }
  }

  const mapStateToProps = state => ({ errors: errorSelector(state) });

  return connect(mapStateToProps)(ErrorHandler);
};

export default withErrors;
