import { connect } from 'react-redux';

import { requestLogout } from 'actions/auth';

import {
  openCreateMasterAccountModal,
  closeCreateMasterAccountModal,
  createMasterAccount,
  clearErrorInfo,
} from '../actions/masterAccount';

import Layout from '../components/Layout/layout';


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isCreateModalOpen: state.masterAccount.isCreateModalOpen,
  isValidCreateMasterAccountRequest: state.masterAccount.isValidRequest,
  createMasterAccountErrorMsg: state.masterAccount.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  onRequestLogoutClick: () => {
    dispatch(requestLogout());
  },
  onCreateAccountClick: () => {
    dispatch(openCreateMasterAccountModal());
  },
  onCloseCreateMasterAccountModal: () => {
    dispatch(closeCreateMasterAccountModal());
  },
  createMasterAccount: model => {
    dispatch(createMasterAccount(model, dispatch));
  },
  clearMasterAccountErrorMsg: () => {
    dispatch(clearErrorInfo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
