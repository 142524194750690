import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getBoundingClientRect } from 'utils/reactDOM';

import { TABLE_HEADER_HEIGHT } from '../constants/dimensions';
import styles from 'internal-tools/styles/table.css';


export default class DisplayResultsTable extends React.Component<any, any> {
  buildTableLoader() {
    const { results, loadingMore } = this.props;

    if (!loadingMore) return null;

    return (
      <TableRow key={results.length + 1}>
        <TableCell>
          <div className={styles.accountRow}>
            <CircularProgress color='inherit' />
          </div>
        </TableCell>
      </TableRow>
    );
  }

  buildTableRows() {
    const { results, buildSingleRow } = this.props;
    return results.map(buildSingleRow);
  }

  onScroll = () => {
    const {
      loadingMore,
      getResultsByPagination,
      tableHeight,
    } = this.props;

    if (loadingMore) return;

    const { tableBody, wrapper } = this.refs;

    const { bottom: tableBottom } = (wrapper as any).getBoundingClientRect();
    const { bottom: tableBodyBottom } = getBoundingClientRect(tableBody) || { bottom: 0 };

    const PIXELS_FROM_BOTTOM = tableHeight || 50;
    const pixelsToBottom = tableBodyBottom - tableBottom;

    if (pixelsToBottom < PIXELS_FROM_BOTTOM) {
      getResultsByPagination();
    }
  }

  render() {
    const {
      loading,
      results,
      tableHeight,
      tableHeaderRow: TableHeaderRow,
    } = this.props;

    if (!tableHeight) return null;

    if (loading) {
      return (
        <div className={styles.accountRow}>
          <CircularProgress size={100} thickness={5} color='inherit' />
        </div>
      );
    }

    if (results.length) {
      const tableRows = this.buildTableRows();
      const tableLoader = this.buildTableLoader();

      return (
        <div className={styles.table} onScroll={this.onScroll} ref='wrapper'>
          <Table style={{ height: `${tableHeight - TABLE_HEADER_HEIGHT}` }}>
            <TableHead style={{ height: TABLE_HEADER_HEIGHT }}>
              <TableHeaderRow />
            </TableHead>
            {/* @ts-ignore */}
            <TableBody ref='tableBody'>
              {tableRows}
              {tableLoader}
            </TableBody>
          </Table>
        </div>
      );
    }
    return <p>No results</p>;
  }
}
