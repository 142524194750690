import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import * as Colors from 'utils/colors';
import sharedStyles from 'internal-tools/styles/shared.css';


const baseClasses = {
  disabled: sharedStyles.submitButtonDisabled,
  label: sharedStyles.submitButtonLabel,
  root: sharedStyles.submitButton,
};

const baseLoaderClasses = {
  root: sharedStyles.progress,
};

export default class SubmitButton extends React.Component<any, any> {
  static defaultProps = {
    classes: baseClasses,
    loaderClasses: baseLoaderClasses,
  }

  render() {
    const {
      classes,
      disabled,
      isFetching,
      loaderClasses,
      status,
    } = this.props;
    if (isFetching) {
      if (status === 'pending') {
        return <CircularProgress size={24} classes={{ root: sharedStyles.progress, ...loaderClasses }} />;
      }

      const buttonText = status === 'success' ? 'check_circle_outline' : 'error_outline';
      return (
        <Icon style={{ color: Colors.white }}>{buttonText}</Icon>
      );
    }

    return (
      <Button
        variant='contained'
        type='submit'
        classes={{ ...baseClasses, ...classes }}
        disabled={disabled}>
        Apply Changes
      </Button>
    );
  }
}
