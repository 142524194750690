import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ToggleCheckbox from '@material-ui/icons/CheckBox';

import withErrors from 'internal-tools/containers/WithErrors';
import SubmitButton from 'internal-tools/components/SubmitButton';
import * as actions from 'internal-tools/actions/accounts';
import { DATABASE_BACKED_ROLES } from 'internal-tools/constants/user';
import styles from 'internal-tools/styles/modal.css';
import accountStyles from 'internal-tools/styles/accounts.css';
import sharedStyles from 'internal-tools/styles/shared.css';
import * as inlineStyles from './styles';
import { capitalizeFirstLetter } from 'utils/strings';
import { FullUserRoles, LimitedUserRoles, userRolesEnum } from 'constants/user';

import { BLANK_USER } from 'internal-tools/constants/user';


export class UserForm extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      ...props.user,
      dirty: false,
      password: '',
      ticket: '',
      transferUserId: '',
    };

    this.initUserRole = props.user.role;
    this.initUserStatus = props.user.isActive;
  }

  componentDidUpdate() {
    const {
      fetching: { component, status },
    } = this.props;
    if (component === 'users') {
      if (status === 'success') {
        setTimeout(() => {
          this.props.resetFetching();
          this.props.closeUserModal();
        }, 2500);
      } else if (status === 'failure') {
        setTimeout(this.props.resetFetching, 2500);
      }
    }
  }

  initUserRole;
  initUserStatus;

  ableToSubmit() {
    const isTransferData = this.shouldTransferUserData();

    if (!isTransferData) {
      return this.state.dirty && Boolean(this.state.ticket);
    }

    return this.state.dirty && Boolean(this.state.ticket) && !!this.state.transferUserId;
  }

  buildRoleMenuItems() {
    return DATABASE_BACKED_ROLES.map((role, idx) => (
      <MenuItem
        classes={{
          root: sharedStyles.menuItem,
          selected: sharedStyles.menuItemSelected,
        }}
        key={idx}
        value={role}
      >
        {capitalizeFirstLetter(role)}
      </MenuItem>
    ));
  }

  buildUsersMenuItems() {
    return this.props.users.map((user, idx) => (
      <MenuItem
        classes={{
          root: sharedStyles.menuItem,
          selected: sharedStyles.menuItemSelected,
        }}
        key={idx}
        value={user.uid}
      >
        {user.username && user.username.trim() ? `${user.username || ''} (${user.email})` : user.email}
      </MenuItem>
    ));
  }

  showError(field) {
    if (this.props.errors[field]) {
      return <p className={accountStyles.userError}>{this.props.errors[field]}</p>;
    }
    return null;
  }

  updateField(field) {
    return event => this.setState({ [field]: event.target.value, dirty: true });
  }

  updateTicket = event => {
    this.setState({ ticket: event.target.value });
  };

  updateUserEnabled = () => {
    this.setState(state => ({ isActive: !state.isActive, dirty: true }));
  };

  updateUserRole = event => {
    const role = event.target.value;
    this.setState({ role, dirty: true });
  };

  shouldTransferUserData() {
    if (!this.props.isUpdating || !this.initUserStatus) {
      return false;
    }

    if (!this.state.isActive && FullUserRoles.includes(this.state.role)) {
      return true;
    }

    if (
      FullUserRoles.includes(this.initUserRole) &&
      (LimitedUserRoles.includes(this.state.role) || this.state.role === userRolesEnum.VISITOR)
    ) {
      return true;
    }

    return false;
  }

  handleSubmit = event => {
    event.preventDefault();
    // The following destructuring became necessary due to a Flow issue. May warrant some cleanup.
    const { email, firstName, lastName, isActive, password, role, ticket, uid, transferUserId } = this.state;
    const user = {
      email,
      firstName,
      lastName,
      isActive,
      password: password || undefined,
      role,
      uid,
    };

    this.props.submit(user, ticket, this.shouldTransferUserData() ? transferUserId : '');
  };

  render() {
    const {
      closeUserModal,
      fetching: { component, status },
      passwordOptional,
    } = this.props;
    const { checkboxStyles } = inlineStyles;

    return (
      <div className={styles.modalBackground} onClick={closeUserModal}>
        <div onClick={e => e.stopPropagation()} className={styles.modalContainer}>
          <form className={styles.modalContent} onSubmit={this.handleSubmit}>
            <div className={styles.group}>
              <div className={accountStyles.userNameInput}>
                <label className={styles.modalLabel} htmlFor='firstName'>
                  First Name
                </label>
                <input
                  type='text'
                  className={styles.modalInput}
                  id='firstName'
                  value={this.state.firstName}
                  onChange={this.updateField('firstName')}
                  placeholder='Enter first name'
                />
                {this.showError('firstName')}
              </div>

              <div className={`${accountStyles.userNameInput} ${accountStyles.lastNameInput}`}>
                <label className={styles.modalLabel} htmlFor='lastName'>
                  Last Name
                </label>
                <input
                  type='text'
                  className={styles.modalInput}
                  id='lastName'
                  value={this.state.lastName}
                  onChange={this.updateField('lastName')}
                  placeholder='Enter last name'
                />
                {this.showError('lastName')}
              </div>
            </div>

            <label className={styles.modalLabel} htmlFor='email'>
              Email*
            </label>
            <input
              className={styles.modalInput}
              type='email'
              id='email'
              value={this.state.email}
              onChange={this.updateField('email')}
              placeholder='Enter email address'
            />
            {this.showError('email')}

            <label className={styles.modalLabel} htmlFor='password'>
              Password{passwordOptional ? '' : '*'}
            </label>
            <input
              className={styles.modalInput}
              id='password'
              value={this.state.password}
              type='password'
              onChange={this.updateField('password')}
              placeholder={`Enter new password${passwordOptional ? ' (optional)' : ''}`}
            />
            {this.showError('password')}

            <div className={styles.group}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='role'>Role*</InputLabel>
                <Select
                  classes={{
                    root: sharedStyles.selectRoot,
                    outlined: sharedStyles.outlinedInput,
                  }}
                  onChange={this.updateUserRole}
                  MenuProps={{
                    classes: {
                      paper: sharedStyles.menuPaper,
                    },
                  }}
                  input={<OutlinedInput labelWidth={50} name='role' id='role' />}
                  value={this.state.role}
                  variant='outlined'
                >
                  {this.buildRoleMenuItems()}
                </Select>
                {this.props.errors.role && (
                  <FormHelperText error classes={{ error: accountStyles.userError }}>
                    {this.props.errors.role}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControlLabel
                label='Enable'
                onChange={this.updateUserEnabled}
                control={
                  <Checkbox
                    checkedIcon={<ToggleCheckbox style={checkboxStyles.iconStyle} />}
                    classes={{ root: accountStyles.userCheckbox }}
                    checked={this.state.isActive}
                  />
                }
              />
            </div>

            {this.shouldTransferUserData() && (
              <div className={styles.group}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='form-authorship'>Transferring Form Authorship*</InputLabel>
                  <Select
                    classes={{
                      root: sharedStyles.selectRoot,
                      outlined: sharedStyles.outlinedInput,
                    }}
                    onChange={event => {
                      const transferUserId = event.target.value;

                      this.setState({ transferUserId, dirty: true });
                    }}
                    MenuProps={{
                      classes: {
                        paper: sharedStyles.menuPaper,
                      },
                    }}
                    input={<OutlinedInput labelWidth={250} name='transferUserId' id='form-authorship' />}
                    value={this.state.transferUserId}
                    variant='outlined'
                  >
                    {this.buildUsersMenuItems()}
                  </Select>
                </FormControl>
              </div>
            )}

            <div className={styles.group}>
              <input
                className={`${styles.modalInput} ${styles.modalTicketInput}`}
                value={this.state.ticket}
                onChange={this.updateTicket}
                placeholder='Enter Jira ticket*'
              />
              <SubmitButton
                classes={{ root: sharedStyles.userSubmit, disabled: sharedStyles.userSubmitDisabled }}
                disabled={!this.ableToSubmit()}
                isFetching={component === 'users'}
                loaderClasses={{ root: sharedStyles.userProgress }}
                status={status}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.accounts.ui.fetching,
});

const mapDispatchToProps = dispatch => {
  const { closeUserModal, resetFetching } = actions;
  return bindActionCreators({ closeUserModal, resetFetching }, dispatch);
};

const selector = state => state.accounts.ui.error;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrors(UserForm, Object.keys(BLANK_USER), selector));
